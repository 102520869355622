/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'mSFCGo9nKYaKDn7oToYGPt.jpg': { uri: '/images/mSFCGo9nKYaKDn7oToYGPt.jpg' },
'qjDu3AaUAH7TuZPJeqJbWT.png': { uri: '/images/qjDu3AaUAH7TuZPJeqJbWT.png' },
'2BRrYHEcfjgUm6CivJZKeM.png': { uri: '/images/2BRrYHEcfjgUm6CivJZKeM.png' },
't1B7FRNZxnqek4xyNt8WTf.png': { uri: '/images/t1B7FRNZxnqek4xyNt8WTf.png' },
'5uJYeycuUEnzp1qYAv4BqH.png': { uri: '/images/5uJYeycuUEnzp1qYAv4BqH.png' },
'j47WyCFw81xKd6Zsd8Fyxy.png': { uri: '/images/j47WyCFw81xKd6Zsd8Fyxy.png' },
'1fN8AczGse78S9bfxkJ6ZZ.png': { uri: '/images/1fN8AczGse78S9bfxkJ6ZZ.png' },
'48XgYjw7SGKvncVYoKzwLT.png': { uri: '/images/48XgYjw7SGKvncVYoKzwLT.png' },
'naUtFDA8pwUGp7PGzeigkq.png': { uri: '/images/naUtFDA8pwUGp7PGzeigkq.png' },
'6AvY9BNsQ5Fp42LiYz2sUX.png': { uri: '/images/6AvY9BNsQ5Fp42LiYz2sUX.png' },
'kJY1SNRRVzR1UQor3wh4ak.png': { uri: '/images/kJY1SNRRVzR1UQor3wh4ak.png' },
'uYWVkhNmWxnJwC6NmG69G5.png': { uri: '/images/uYWVkhNmWxnJwC6NmG69G5.png' },
'hwFLrMhfTo5zT8mXSsWAAo.png': { uri: '/images/hwFLrMhfTo5zT8mXSsWAAo.png' },
'iq5mt6Do9imz1KEumFkBfa.png': { uri: '/images/iq5mt6Do9imz1KEumFkBfa.png' },
'ha3CJWnRvoXVqsqgMGSUTF.png': { uri: '/images/ha3CJWnRvoXVqsqgMGSUTF.png' },
'gNBchHA2ZmiMdoocGkJtEC.png': { uri: '/images/gNBchHA2ZmiMdoocGkJtEC.png' },
'evuBD4DwhFWH8mcwsD1NYr.jpg': { uri: '/images/evuBD4DwhFWH8mcwsD1NYr.jpg' },
'qjZ4Bk8BqqrCbbGnfKQdH5.png': { uri: '/images/qjZ4Bk8BqqrCbbGnfKQdH5.png' },
'8QvLDYFu1J7KM1ac5VFC4z.png': { uri: '/images/8QvLDYFu1J7KM1ac5VFC4z.png' },
'fZnFMF9T93ukyxTPvFptNt.png': { uri: '/images/fZnFMF9T93ukyxTPvFptNt.png' },
'wjNu3U5pKWfB4ZcWGj53L1.jpg': { uri: '/images/wjNu3U5pKWfB4ZcWGj53L1.jpg' },
'319dmTTVNp1dPGvxdPCkjc.jpg': { uri: '/images/319dmTTVNp1dPGvxdPCkjc.jpg' },
'v8ns8D5J6Xbfs39i6Sg12a.jpg': { uri: '/images/v8ns8D5J6Xbfs39i6Sg12a.jpg' },
'6KCUAKAu8TcGxjy9RaZsQx.jpg': { uri: '/images/6KCUAKAu8TcGxjy9RaZsQx.jpg' },
'bScoR1cyAfxFRc4NBmVTSj.png': { uri: '/images/bScoR1cyAfxFRc4NBmVTSj.png' },
'1QGEoCpqRLWZqmX54DCs5b.jpg': { uri: '/images/1QGEoCpqRLWZqmX54DCs5b.jpg' },
'x5DsQ6sLjrrCtqkQ7ggpjv.jpg': { uri: '/images/x5DsQ6sLjrrCtqkQ7ggpjv.jpg' },
'fQ5ycraK18793JVMi8odiY.jpg': { uri: '/images/fQ5ycraK18793JVMi8odiY.jpg' }
}

export default imageStaticSourcesByFileName
